export const disableScroll = () => {
  const body = document.body;
  const initialWidth = body.offsetWidth;
  body.style.overflow = 'hidden'; // Hide the scrollbars
  const scrollWidth = body.offsetWidth - initialWidth;
  body.style.marginRight = `${scrollWidth}px`; // Adjust body's margin to compensate for scrollbar

  // Adjust bottom bar's position
  const bottomBar = document.querySelector('.bottom-bar');
  if (bottomBar) {
    // bottomBar.style.transform = `translateX(${scrollWidth}px)`;
  }
};

export const enableScroll = () => {
  const body = document.body;
  body.style.overflow = 'auto'; // Restore default overflow behavior
  body.style.marginRight = '0px'; // Reset body's margin

  // Reset bottom bar's position
  const bottomBar = document.querySelector('.bottom-bar');
  if (bottomBar) {
    // bottomBar.style.transform = 'translateX(0)';
  }
};


// export const disableScroll = () => {
//   const body = document.body;
//   const initialWidth = body.offsetWidth;
//   body.style.overflow = 'hidden'; // Hide the scrollbars
//   const scrollWidth = body.offsetWidth - initialWidth;
//   body.style.marginRight = `${scrollWidth}px`; // Adjust body's margin to compensate for scrollbar
// };

// export const enableScroll = () => {
//   const body = document.body;
//   body.style.overflow = ''; // Restore default overflow behavior
//   body.style.marginRight = '0px'; // Reset body's margin
// };

// export const enableScroll = () => {
//   const body = document.body;
//   body.style.overflow = 'auto'; // Restore default overflow behavior
//   removeScrollbarOverlay();
// };

// export const disableScroll = () => {
//   const body = document.body;
//   body.style.overflow = 'hidden'; // Hide the scrollbars
//   createScrollbarOverlay();
// };

// const createScrollbarOverlay = () => {
//   const overlay = document.createElement('div');
//   overlay.classList.add('overlay-scrollbar');
//   document.body.appendChild(overlay);

//   // Add class to show the scrollbar overlay smoothly
//   overlay.classList.add('show');
// };

// const removeScrollbarOverlay = () => {
//   const overlay = document.querySelector('.overlay-scrollbar');
//   if (overlay) {
//     // Hide scrollbar overlay smoothly
//     overlay.classList.remove('show');
//     overlay.addEventListener('transitionend', () => {
//       overlay.remove(); // Remove overlay after transition ends
//     }, { once: true });
//   }
// };


// export const enableScroll = () => {
//   // document.body.style.overflow = "auto";
//    const body = document.body;
//     body.style.overflow = 'auto'; // Restore default overflow behavior
//     body.classList.remove('overlay-scrollbar');
// };

// export const disableScroll = () => {
//   // document.body.style.overflow = "hidden";
//    const body = document.body;
//      body.style.overflow = 'hidden'; // Hide the scrollbars
//     body.classList.add('overlay-scrollbar');
// };

export const validateImage = (imgSrc, type) => {
  try {
    if (type === "thumbnail") imgSrc = require(`./images/thumbnail/${imgSrc}`);
    else if (type === "full") imgSrc = require(`./images/full/${imgSrc}`);
    else if (type === "icon") imgSrc = require(`./images/icon/${imgSrc}`);
    else if (type === "home-carousel")
      imgSrc = require(`./images/home-carousel/${imgSrc}`);
    else imgSrc = require(`./images/default.jpg`);
  } catch {
    if (type === "thumbnail")
      imgSrc = require(`./images/thumbnail/default.jpg`);
    else if (type === "full") imgSrc = require(`./images/full/default.jpg`);
    else if (type === "icon") imgSrc = require(`./images/icon/default.jpg`);
    else if (type === "home-carousel")
      imgSrc = require(`./images/home-carousel/default.png`);
    else imgSrc = require(`./images/default.jpg`);
  }
  return imgSrc;
};

export const imageFallback = (type) => {
  let imgSrc = "";
  if (type === "thumbnail") imgSrc = require(`./images/thumbnail/default.jpg`);
  else if (type === "full") imgSrc = require(`./images/full/default.jpg`);
  else if (type === "icon") imgSrc = require(`./images/icon/default.jpg`);
  else imgSrc = require(`./images/default.jpg`);
  return imgSrc;
};

export const getTitleCase = (str) => {
  // if (str) {
  //   const titleCase = str
  //     .toLowerCase()
  //     .split(" ")
  //     .map((word) => {
  //       return word.charAt(0).toUpperCase() + word.slice(1);
  //     })
  //     .join(" ");

  //   return titleCase;
  // }
  // return " ";
  return str;
};

export const getParagraphCase = (inputStr) => {
  // if (inputStr) {
  //   let words = inputStr.toLowerCase().split(" ");
  //   words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
  //   for (let i = 1; i < words.length; i++) {
  //     if (/[\.\?!]\s*/.test(words[i - 1])) {
  //       // using regex to match period, question mark, or exclamation mark with optional spaces
  //       words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  //     } else {
  //       words[i] = words[i].toLowerCase();
  //     }
  //   }
  //   return words.join(" ");
  // }
  // return "";
  return inputStr;
};

//function to convert rgba or rgb color to hex color
export const rgbaToHex = (color) => {
  if (color?.startsWith('rgba')) {
    const rgba = color.match(/(\d+(\.\d+)?)/g);
    const r = parseInt(rgba[0], 10);
    const g = parseInt(rgba[1], 10);
    const b = parseInt(rgba[2], 10);
    const a = parseFloat(rgba[3]);
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
  } else if (color?.startsWith('rgb')) {
    const rgb = color.match(/(\d+(\.\d+)?)/g);
    const r = parseInt(rgb[0], 10);
    const g = parseInt(rgb[1], 10);
    const b = parseInt(rgb[2], 10);
    return `#${((1 << 16) + (r << 8) + g + b).toString(16).slice(1)}`;
  }
  return null;
};

//hex to rgba function
export const HexToRgba = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

//rgb to rgba converter function
export const rgbToRgba = (rgb, alpha) => {
  const regex = /(\d{1,3}), (\d{1,3}), (\d{1,3})/;
  const [, r, g, b] = regex.exec(rgb);
  const rgba = `rgba(${r}, ${g}, ${b}, ${alpha})`;
  return rgba;
};

//function to get the r, g, and b values out of a hex color
  export const getRGBValues = (hex) => {
    if(!hex) {
      return "";
    }
    // Remove # if present
    hex = hex?.replace('#', '');

    // Parse hex values to separate R, G, B values
    const r = parseInt(hex?.substring(0, 2), 16);
    const g = parseInt(hex?.substring(2, 4), 16);
    const b = parseInt(hex?.substring(4, 6), 16);

    return `${r}, ${g}, ${b}`;
  };

// export const getWarmAndColdColors = (hexColor) => {
//   // Convert hex to RGB
//   const hexToRgb = (hex) =>
//     hex.match(/\w\w/g).map((x) => parseInt(x, 16));

//   const rgbColor = hexToRgb(hexColor);

//   // Function to lighten or darken a color by a given percentage
//   const shadeColor = (color, percent) => {
//     const factor = percent / 100;
//     return Math.round(color + (percent > 0 ? (255 - color) * factor : color * factor));
//   };

//   // Calculate lighter and darker shades
//   const coldColor = `rgb(${shadeColor(rgbColor[0], 30)}, ${shadeColor(rgbColor[1], 30)}, ${shadeColor(rgbColor[2], 30)})`;
//   const warmColor = `rgb(${shadeColor(rgbColor[0], -30)}, ${shadeColor(rgbColor[1], -30)}, ${shadeColor(rgbColor[2], -30)})`;

//   return { coldColor, warmColor };
// };

export const getWarmAndColdColors = (hexColor) => {
  if(!hexColor) {
    return null;
  }
  // Convert hex to RGB
  const hexToRgb = (hex) =>
    hex?.match(/\w\w/g).map((x) => parseInt(x, 16));

  // Convert RGB to HSL
  const rgbToHsl = (r, g, b) => {
    r /= 255;
    g /= 255;
    b /= 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
      }

      h /= 6;
    }

    return [h * 360, s * 100, l * 100];
  };

  // Convert HSL to RGB
  const hslToRgb = (h, s, l) => {
    h /= 360;
    s /= 100;
    l /= 100;
    let r, g, b;

    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  };

  const rgbColor = hexToRgb(hexColor);
  const [h, s, l] = rgbToHsl(...rgbColor);

  // Adjust lightness for non-black colors
  if (l > 10) {
    const warmColor = `rgb(${hslToRgb(h, s, Math.max(0, l - 20)).join(', ')})`;
    const coldColor = `rgb(${hslToRgb(h, s, Math.min(100, l + 20)).join(', ')})`;

    return { coldColor, warmColor };
  }

  // For black color, adjust lightness to create variations
  const warmBlack = `rgb(${hslToRgb(h, s, Math.min(20, l + 20)).join(', ')})`;
  const coldBlack = `rgb(${hslToRgb(h, s, Math.min(40, l + 40)).join(', ')})`;

  return { coldColor: coldBlack, warmColor: warmBlack };
};

//function to get contrast color for a given color
export const getContrastColor = (backgroundColor, lightBgContrastColor = "#000000", darkBgContrastColor = "#FFFFFF", maxDarkBgLuminance = 0.5) => {
  if (!backgroundColor) {
    return null;
  }
  // Convert hex color to RGB
  let r, g, b;
  if (backgroundColor.charAt(0) === "#") {
    backgroundColor = backgroundColor.substr(1);
    r = parseInt(backgroundColor.substr(0, 2), 16);
    g = parseInt(backgroundColor.substr(2, 2), 16);
    b = parseInt(backgroundColor.substr(4, 2), 16);
  } else {
    // If it's not in hex format, handle other formats (e.g., rgb, rgba)
    const matchColors = backgroundColor.match(/\d+/g);
    if (matchColors.length === 3) {
      [r, g, b] = matchColors.map((color) => parseInt(color, 10));
    } else if (matchColors.length === 4) {
      [r, g, b] = matchColors.slice(0, 3).map((color) => parseInt(color, 10));
    } else {
      return null; // Unable to determine color
    }
  }

  // Calculate luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Return contrast color based on luminance
  return luminance > maxDarkBgLuminance ? lightBgContrastColor : darkBgContrastColor;
};

// export const handleCurrentLocation = async (setCurrentLocation, setCurrentAddress, getNearestDeliveryBranch, selectedRestaurant) => {
//   let location = null;
//   const successCallback = async (position) => {
//     const lat = position.coords.latitude;
//     const lng = position.coords.longitude;
//     // Reverse geocoding to get location details
//     const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
//     try {
//       const response = await fetch(geocodingUrl);
//       const data = await response.json();
//       const addressComponents = data.results[0].address_components;
//       if (addressComponents) {
//         location = addressComponents.reduce((prev, curr) => {
//           if (curr.types.includes("route")) return curr.long_name;
//           if (curr.types.includes("sublocality")) return curr.long_name;
//           if (curr.types.includes("locality")) return curr.long_name;
//           if (curr.types.includes("administrative_area_level_1"))
//             return curr.long_name;
//           return prev;
//         }, "");
//       }
//       setCurrentLocation(lat, lng, location);
//       if(setCurrentAddress) {
//         setCurrentAddress("current-location");
//       }
//       // if(getNearestDeliveryBranch && selectedRestaurant) {
//       //  getNearestDeliveryBranch(selectedRestaurant, lat, lng);
//       // }
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const errorCallback = async (error) => {
//     console.error(error);

//     if (error.code === 1) {
//       // User denied geolocation permission
//       const confirmMessage =
//         "Make sure location access is enabled on your browser, and location is turned on on your device ";
//       window.alert(confirmMessage);
//       setCurrentLocation(null,null,null);
//     } else {
//       // Fallback mechanism - use IP geolocation to get user's location
//       try {
//         const response = await fetch("https://ipapi.co/json/");
//         const data = await response.json();
//         const lat = data.latitude;
//         const lng = data.longitude;
//         // Reverse geocoding to get location details
//         const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
//         try {
//           const response = await fetch(geocodingUrl);
//           const data = await response.json();
//           const addressComponents = data.results[0].address_components;
//           let location = "";
//           if (addressComponents) {
//             location = addressComponents.reduce((prev, curr) => {
//               if (curr.types.includes("route")) return curr.long_name;
//               if (curr.types.includes("sublocality")) return curr.long_name;
//               if (curr.types.includes("locality")) return curr.long_name;
//               if (curr.types.includes("administrative_area_level_1"))
//                 return curr.long_name;
//               return prev;
//             }, "");
//           }
//           setCurrentLocation(lat, lng, location);
//           if(setCurrentAddress) {
//           setCurrentAddress("current-location");
//            }
//           // if(getNearestDeliveryBranch && selectedRestaurant) {
//           //   getNearestDeliveryBranch(selectedRestaurant, lat, lng);
//           // }
//         } catch (error) {
//           console.error(error);
//           setCurrentLocation(null,null,null);
//         }
//       } catch (error) {
//         setCurrentLocation(null,null,null);
//         console.error(error);
//       }
//     }
//   };

//   if (navigator.geolocation) {
//     navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
//   } else {
//     console.error("Geolocation not supported");
//   }
// };

// //function to handle address/location change, when on delivery, and when cart has items
// export const handleLocationChangeOnDelivery = (deliveryMethod, userSelectedBranch, cart, deliverySelectedBranch, currentAddress, cartDeliveryMethod, cartBranch, products, updateCartDetail, setOpenNoDeliveryClearCartConfirm, setopenLocationChangeCartDeliveryPricesAndItemsUpdate, setOpenItemsNotAvailableDeliveryConfirm, setOpenPricesChangeDeliveryConfirm) => {
//       //ON USER LOCATION/ADDRESS CHANGE
//     if(cart?.length > 0) {
//       if(cartDeliveryMethod === "delivery") {
//         //if new location/address has no delivery, notify the user that he will loose the items in the cart
//         if(deliverySelectedBranch === "" && currentAddress) {
//           setOpenNoDeliveryClearCartConfirm(true);
//           return false;
//         }
//        //if new location/address has delivery, but from a different branch
//        if(deliverySelectedBranch !== cartBranch) {
//          //check if there are any items in the cart, not available for delivery from the new selected branch
//         const someItemsNotAvailable = cart?.filter((item)=>{
//           return products?.find((product)=>{
//           return product?.uuid === item?.uuid && !((product?.finances?.find((finance)=>{
//             return finance?.defaultItemPrice && finance?.finance[cartDeliveryMethod]?.available
//             })) && product?.finances?.find((finance)=>{
//             return  finance?.branch?.uuid === deliverySelectedBranch && finance?.finance[cartDeliveryMethod]?.available
//             })
//             )
//           })
//         })
//       //check for any price changes
//       const someItemsWithDifferentPrices = cart?.filter((item)=>{
//         return products?.find((product)=>{
//           return product?.uuid === item?.uuid && getProductPrice(deliveryMethod, product?.finances, userSelectedBranch, deliverySelectedBranch) !== getProductPrice(cartDeliveryMethod, product?.finances, cartBranch, cartBranch) && !(someItemsNotAvailable?.find((itemNotAvailable)=>{
//             return itemNotAvailable?.uuid === item?.uuid
//           }))
//         })
//       })
//       if(someItemsNotAvailable?.length > 0 && someItemsWithDifferentPrices?.length > 0) {
//         setopenLocationChangeCartDeliveryPricesAndItemsUpdate(true);
//         return false;
//       }
//       if(someItemsNotAvailable?.length > 0) {
//         setOpenItemsNotAvailableDeliveryConfirm(true);
//         return false;
//       }
//       if(someItemsWithDifferentPrices?.length > 0) {
//         setOpenPricesChangeDeliveryConfirm(true);
//         return false;
//       }
//         //else
//         updateCartDetail('cartBranch', deliverySelectedBranch);
//        }
//       }
//     }
//   return true;
// }

// //function to share current url to facebook
// export const shareToFacebook = (thumbnailUrl) => {
//   // const url = encodeURIComponent(window.location.href);
//   const url = encodeURIComponent(`${thumbnailUrl}&timestamp=${Date.now()}`);
//   const title = encodeURIComponent("Your App Title");
//   // const facebookShareUrl = `https://www.facebook.com/sharer.php?u=${url}&t=${title}&picture=${thumbnailUrl}`;
//   const facebookShareUrl = `https://www.facebook.com/sharer.php?u=${`${url}`}`;

//   const a = document.createElement("a");
//   a.setAttribute("href", facebookShareUrl);
//   a.setAttribute("target", "_blank");
//   a.setAttribute("rel", "noopener noreferrer");
//   a.click();

//   // window.open(facebookShareUrl, '_blank');
// };

export const shareToFacebook = (thumbnailUrl) => {
  const url = encodeURIComponent(`${thumbnailUrl}&timestamp=${Date.now()}`);
  const facebookShareUrl = `https://www.facebook.com/sharer.php?u=${url}`;

  // Helper function to create and click an anchor link
  const openLink = (link) => {
    const a = document.createElement("a");
    a.setAttribute("href", link);
    a.setAttribute("target", "_blank");
    a.setAttribute("rel", "noopener noreferrer");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // Check if iOS device
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  if (isIOS) {
    // For iOS devices, try opening the Facebook app if installed
    window.location.href = `fb://share/?link=${url}`;
    // If the Facebook app is not installed, open the share URL in Safari
    setTimeout(() => {
      if (document.visibilityState === 'visible') {
        window.location.href = facebookShareUrl;
      }
    }, 1000); // Wait for 1 second before falling back to the web URL
  } else {
    // For other devices and browsers, open the Facebook share URL in a new tab
    openLink(facebookShareUrl);
  }
};


// export const shareToFacebook = (thumbnailUrl) => {
//   const url = encodeURIComponent(window.location.href);
//   const title = encodeURIComponent('Your App Title');
//   const facebookShareUrl = `https://www.facebook.com/sharer.php?u=${url}&t=${title}&picture=${thumbnailUrl}`;

//   // Save the current scroll position
//   const scrollX = window.scrollX || window.pageXOffset || document.documentElement.scrollLeft;
//   const scrollY = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

//   // Open the Facebook sharing window
//   const facebookWindow = window.open(facebookShareUrl, '_blank');

//   // Handle the return to the app using focus and location change
//   const handleReturnToApp = () => {
//     // Reload the app with the saved scroll position
//     window.location.replace(window.location.href);
//     window.scrollTo(scrollX, scrollY);

//     // Remove the event listener to avoid any potential conflicts
//     window.removeEventListener('focus', handleReturnToApp);
//   };

//   // Add an event listener to detect when the user returns to the app
//   window.addEventListener('focus', handleReturnToApp);

//   // Poll the window for changes to detect the return to the app
//   const checkWindowClosed = setInterval(() => {
//     if (facebookWindow.closed) {
//       handleReturnToApp();
//       clearInterval(checkWindowClosed);
//     }
//   }, 100);

//   // Ensure that the Facebook sharing window is focused
//   if (facebookWindow) {
//     facebookWindow.focus();
//   }
// };

//function to share current url to instagram
export const shareToInstagram = () => {
  const url = encodeURIComponent("https://app.gain-intel.com/");
  const instagramShareUrl = `https://www.instagram.com/?url=${url}`;

  const a = document.createElement("a");
  a.setAttribute("href", instagramShareUrl);
  a.setAttribute("target", "_blank");
  a.setAttribute("rel", "noopener noreferrer");
  a.click();
  //window.open(instagramShareUrl, '_blank');
};

export const shareViaGmail = (thumbnailUrl) => {
  const url = encodeURIComponent(window.location.href);
  const subject = encodeURIComponent("Check out this item");
  // const body = encodeURIComponent(
  //   `I wanted to share this link with you: ${window.location.href}`
  // );
    const body = encodeURIComponent(
    `I wanted to share this link with you: ${thumbnailUrl}`
  );
  const gmailUrl = `https://mail.google.com/mail/?view=cm&to=&su=${subject}&body=${body}&image=${thumbnailUrl}`;

  const a = document.createElement("a");
  a.setAttribute("href", gmailUrl);
  a.setAttribute("target", "_blank");
  a.setAttribute("rel", "noopener noreferrer");
  a.click();
  //window.open(gmailUrl);
};

// export const shareViaGmail = (thumbnailUrl) => {
//   const url = encodeURIComponent(window.location.href);
//   const subject = encodeURIComponent('Check out this item');
//   const body = encodeURIComponent(`I wanted to share this link with you: ${window.location.href}`);
//   const gmailUrl = `https://mail.google.com/mail/?view=cm&to=&su=${subject}&body=${body}&image=${thumbnailUrl}`;

//   // Save the current scroll position
//   const scrollX = window.scrollX || window.pageXOffset || document.documentElement.scrollLeft;
//   const scrollY = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

//   // Open the Gmail sharing window
//   const gmailWindow = window.open(gmailUrl, '_blank');

//   // Handle the return to the app using focus and location change
//   const handleReturnToApp = () => {
//     // Reload the app with the saved scroll position
//     window.location.replace(window.location.href);
//     window.scrollTo(scrollX, scrollY);

//     // Remove the event listener to avoid any potential conflicts
//     window.removeEventListener('focus', handleReturnToApp);
//   };

//   // Add an event listener to detect when the user returns to the app
//   window.addEventListener('focus', handleReturnToApp);

//   // Poll the window for changes to detect the return to the app
//   const checkWindowClosed = setInterval(() => {
//     if (gmailWindow.closed) {
//       handleReturnToApp();
//       clearInterval(checkWindowClosed);
//     }
//   }, 100);

//   // Ensure that the Gmail sharing window is focused
//   if (gmailWindow) {
//     gmailWindow.focus();
//   }
// };

// export const shareOnTwitter = (thumbnailUrl) => {
//   // const url = encodeURIComponent(window.location.href);
//   const url = encodeURIComponent(thumbnailUrl);
//   const text = encodeURIComponent("Check out this link:");

//   // const twitterShareUrl = `https://twitter.com/intent/tweet?url=${url}&text=${text}&media=${thumbnailUrl}`;
//   const twitterShareUrl = `https://twitter.com/intent/tweet?url=${url}&text=${text}`;

//   const a = document.createElement("a");
//   a.setAttribute("href", twitterShareUrl);
//   a.setAttribute("target", "_blank");
//   a.setAttribute("rel", "noopener noreferrer");
//   a.click();
//   //window.open(twitterShareUrl, '_blank');
// };

// export const shareOnTwitter = (thumbnailUrl) => {
//   const url = encodeURIComponent(thumbnailUrl);
//   const textWithTimestamp = encodeURIComponent(`Check out this link (${new Date().toLocaleString()}):`);

//   const twitterShareUrl = `https://twitter.com/intent/tweet?url=${url}&text=${textWithTimestamp}`;

//   const a = document.createElement("a");
//   a.setAttribute("href", twitterShareUrl);
//   a.setAttribute("target", "_blank");
//   a.setAttribute("rel", "noopener noreferrer");
//   a.click();
// }

export const shareOnTwitter = (thumbnailUrl) => {
  const url = encodeURIComponent(thumbnailUrl);
  const textWithTimestamp = encodeURIComponent(`Check out this link: ${thumbnailUrl}`);


  const twitterShareUrl = `https://twitter.com/intent/tweet?text=${textWithTimestamp}`;

  const a = document.createElement("a");
  a.setAttribute("href", twitterShareUrl);
  a.setAttribute("target", "_blank");
  a.setAttribute("rel", "noopener noreferrer");
  a.click();
}


// export const shareOnTwitter = (thumbnailUrl) => {
//   const url = encodeURIComponent(window.location.href);
//   const text = encodeURIComponent('Check out this link:');
//   const twitterShareUrl = `https://twitter.com/intent/tweet?url=${url}&text=${text}&media=${thumbnailUrl}`;

//   // Save the current scroll position
//   const scrollX = window.scrollX || window.pageXOffset || document.documentElement.scrollLeft;
//   const scrollY = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

//   // Open the Twitter sharing window
//   const twitterWindow = window.open(twitterShareUrl, '_blank');

//   // Handle the return to the app using focus and location change
//   const handleReturnToApp = () => {
//     // Reload the app with the saved scroll position
//     window.location.replace(window.location.href);
//     window.scrollTo(scrollX, scrollY);

//     // Remove the event listener to avoid any potential conflicts
//     window.removeEventListener('focus', handleReturnToApp);
//   };

//   // Add an event listener to detect when the user returns to the app
//   window.addEventListener('focus', handleReturnToApp);

//   // Poll the window for changes to detect the return to the app
//   const checkWindowClosed = setInterval(() => {
//     if (twitterWindow.closed) {
//       handleReturnToApp();
//       clearInterval(checkWindowClosed);
//     }
//   }, 100);

//   // Ensure that the Twitter sharing window is focused
//   if (twitterWindow) {
//     twitterWindow.focus();
//   }
// };

export const shareOnWhatsApp = (thumbnailUrl) => {
  // const url = encodeURIComponent(window.location.href);
  const url = encodeURIComponent(`${thumbnailUrl}&timestamp=${Date.now()}`);
  const text = encodeURIComponent("Check out this link:");

  // const whatsappShareUrl = `https://api.whatsapp.com/send?text=${text}%20${url}&media=${thumbnailUrl}`;
  const whatsappShareUrl = `https://api.whatsapp.com/send?text=${text}%20${`${url}`}`;

  const a = document.createElement("a");
  a.setAttribute("href", whatsappShareUrl);
  a.setAttribute("target", "_blank");
  a.setAttribute("rel", "noopener noreferrer");
  a.click();
  //window.open(whatsappShareUrl, '_blank');
};

// export const shareOnWhatsApp = (thumbnailUrl) => {
//   const url = encodeURIComponent(window.location.href);
//   const text = encodeURIComponent('Check out this link:');
//   const whatsappShareUrl = `https://api.whatsapp.com/send?text=${text}%20${url}&media=${thumbnailUrl}`;

//   // Save the current scroll position
//   const scrollX = window.scrollX || window.pageXOffset || document.documentElement.scrollLeft;
//   const scrollY = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

//   // Open the WhatsApp sharing window
//   const whatsappWindow = window.open(whatsappShareUrl, '_blank');

//   // Handle the return to the app using focus and location change
//   const handleReturnToApp = () => {
//     // Reload the app with the saved scroll position
//     window.location.replace(window.location.href);
//     window.scrollTo(scrollX, scrollY);

//     // Remove the event listener to avoid any potential conflicts
//     window.removeEventListener('focus', handleReturnToApp);
//   };

//   // Add an event listener to detect when the user returns to the app
//   window.addEventListener('focus', handleReturnToApp);

//   // Poll the window for changes to detect the return to the app
//   const checkWindowClosed = setInterval(() => {
//     if (whatsappWindow.closed) {
//       handleReturnToApp();
//       clearInterval(checkWindowClosed);
//     }
//   }, 100);

//   // Ensure that the WhatsApp sharing window is focused
//   if (whatsappWindow) {
//     whatsappWindow.focus();
//   }
// };

export const copyUrl = async (copySuccess, copyFailed, setCopyUrlSuccess, url="") => {
  if (!url || url === "" || url === null) {
     url = window.location.href;
  }
  try {
    await navigator.clipboard.writeText(url ? url : "");
    setCopyUrlSuccess(copySuccess);
  } catch (err) {
    setCopyUrlSuccess(copyFailed);
  }
};

// export const copyUrlWithThumbnail = async (url, thumbnailUrl, copySuccess, copyFailed) => {
//   try {
//     // Create a temporary element to hold both the URL and thumbnail URL
//     const tempElement = document.createElement("div");
//     tempElement.innerText = `${url}\nThumbnail URL: ${thumbnailUrl}`;

//     // Append the temporary element to the DOM (not visible to the user)
//     document.body.appendChild(tempElement);

//     // Select the content inside the temporary element
//     const range = document.createRange();
//     range.selectNodeContents(tempElement);

//     // Copy the selected content to the clipboard
//     const selection = window.getSelection();
//     selection.removeAllRanges();
//     selection.addRange(range);

//     // Execute the copy command
//     document.execCommand("copy");

//     // Clean up by removing the temporary element
//     document.body.removeChild(tempElement);

//     setCopyUrlSuccess(copySuccess);
//   } catch (err) {
//     setCopyUrlSuccess(copyFailed);
//   }
// };

// const copyUrl = async () => {
//   const copySuccess = getField(dictionary, "copy-url-success", language);
//   const copyFailed = getField(dictionary, "copy-url-failed", language);
//   copyUrlWithThumbnail(window.location.href, thumbnailImage, copySuccess, copyFailed);
// };

const parseColor = (color) => {
  if (color.startsWith('#')) {
    // Hexadecimal color
    return hexToRgb(color);
  } else if (color.startsWith('rgb(')) {
    // RGB color
    return rgbToValues(color);
  } else {
    // Unsupported format
    console.error("Unsupported color format");
    return null;
  }
}

const  hexToRgb = (hex) => {
  // Remove the hash sign if present
  hex = hex.replace('#', '');

  // Parse the hexadecimal string to get the RGB values
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return [r, g, b];
}

export const rgbToValues = (rgb) => {
  // Extract RGB values from the string
  const values = rgb.match(/\d+/g);
  if (values.length !== 3) {
    console.error("Invalid RGB color format");
    return null;
  }
  return values.map(value => parseInt(value));
}


// export const lightenColor = (color, amount) => {
//   const [r, g, b] = parseColor(color);
//   if (!r || !g || !b) return null;

//   // Calculate the lighter shade
//   const newR = Math.min(255, r + amount);
//   const newG = Math.min(255, g + amount);
//   const newB = Math.min(255, b + amount);
//   return `${newR}, ${newG}, ${newB}`;
// }

export const convertToMinutes = (value, unit) => {
  let minutes = 0;
  switch(unit) {
    case "minutes":
      minutes = value;
      break;
    case "hours":
      minutes = value * 60;
      break;
    case "days":
      minutes = value * 24 * 60;
      break;
    case "weeks":
      minutes = value * 7 * 24 * 60;
      break;
    case "months":
      minutes = value * 30 * 24 * 60; // Approximation for months
      break;
    case "years":
      minutes = value * 365 * 24 * 60; // Approximation for years
      break;
    default:
      console.error("Invalid unit provided.");
      break;
  }
  return minutes;
}

  // Function to convert Arabic numbers to Latin numbers
export const toLatinNumbers = (number) => {
    const arabicNumbers = "٠١٢٣٤٥٦٧٨٩";
    const latinNumbers = "0123456789";

    let result = "";
    for (let i = 0; i < number.length; i++) {
      const index = arabicNumbers.indexOf(number[i]);
      result += index !== -1 ? latinNumbers[index] : number[i];
    }

    return result;
  };
